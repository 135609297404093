.App {
  text-align: center;
  height:100%;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SlideIn-appear {
  /*transform: translateX(30px);*/
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  /*transform: translateX(0);*/
  transition: all 1.2s ease-in;
}
.SlideIn-enter {
  opacity: 0.3;
  /*transform: translateX(30px)*/;
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  /*transform: translateX(0);*/
  transition: all 1.2s ease-in 2s;
}
.SlideIn-leave {
  opacity: 1;
  /*transform: translateX(0);*/
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0.3;
  position: absolute;
  width: 100%;
  /*transform: translateX(-30px);*/
  transition: all 1.2s ease-in;
}