.image-gallery {
  width: 100%;
  height: auto;
  padding: 3em 0em;
  /*border: 1px dashed rgba(200, 200, 200, 0.5);*/
}

.image-gallery-slide {
  opacity: 0;
  background: transparent;
  transition-duration: 1s;
}
.image-gallery-slide.center {
  opacity: 1;
}
  /*animation: spinner-rotate .7s cubic-bezier(0.685, 0.0473, 0.346, 1) forwards, blinker .7s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@keyframes blinker {
  from { opacity: 1; }
  to { opacity: .7; }
}
@keyframes spinner-rotate {
  from { transform: rotate(0deg) translateX(0) }
  to { transform: rotate(360deg) translateX(-50px)}
}*/

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: scale-down;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-width: 80vh;
  max-height: 100vh;
}
