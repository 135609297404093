.App {
  text-align: center;
  height:100%;
}


.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.SlideIn-appear {
  /*transform: translateX(30px);*/
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  /*transform: translateX(0);*/
  -webkit-transition: all 1.2s ease-in;
  transition: all 1.2s ease-in;
}
.SlideIn-enter {
  opacity: 0.3;
  /*transform: translateX(30px)*/;
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  /*transform: translateX(0);*/
  -webkit-transition: all 1.2s ease-in 2s;
  transition: all 1.2s ease-in 2s;
}
.SlideIn-leave {
  opacity: 1;
  /*transform: translateX(0);*/
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0.3;
  position: absolute;
  width: 100%;
  /*transform: translateX(-30px);*/
  -webkit-transition: all 1.2s ease-in;
  transition: all 1.2s ease-in;
}
.SlideShowPage {
  min-height: 120vh;
}

.image-gallery {
  width: 100%;
  height: auto;
  padding: 3em 0em;
  /*border: 1px dashed rgba(200, 200, 200, 0.5);*/
}

.image-gallery-slide {
  opacity: 0;
  background: transparent;
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}
.image-gallery-slide.center {
  opacity: 1;
}
  /*animation: spinner-rotate .7s cubic-bezier(0.685, 0.0473, 0.346, 1) forwards, blinker .7s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@keyframes blinker {
  from { opacity: 1; }
  to { opacity: .7; }
}
@keyframes spinner-rotate {
  from { transform: rotate(0deg) translateX(0) }
  to { transform: rotate(360deg) translateX(-50px)}
}*/

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: scale-down;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-width: 80vh;
  max-height: 100vh;
}

.LoginPage {
    height: 100vh;
    background-position: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

